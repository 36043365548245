import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'new-siamese-tech-admin-web';

  constructor(private primengConfig: PrimeNGConfig,public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.layoutService.config.theme = 'red';
        this.layoutService.onConfigUpdate();
    }
}
