import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 403 || err.status == 401) {
          this.router.navigateByUrl('pages/accessdenied');
        } else if (err.status == 404) {
          this.router.navigateByUrl('pages/notfound');
        } else if (err.status == 500) {
          this.router.navigateByUrl('pages/error');
        } else {
          const error = err.error.message || err.statusText;
          console.log(error);
          this.router.navigateByUrl('pages/error');
        }
        return throwError(() => new Error(`Error Status ${err.status}`));
      })
    ) as Observable<HttpEvent<any>>;
  }
}
