import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './modules/layout/app.layout.component';
import { AuthGuard } from './guard/auth.guard';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: 'login',
    data: { breadcrumb: 'Auth' },
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'dashboard',
        data: { breadcrumb: 'Dashboard' },
        loadChildren: () =>
          import('./modules/dashboards/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'customer',
        data: { breadcrumb: 'Customer' },
        loadChildren: () =>
          import('./modules/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
      },
      {
        path: 'technician',
        data: { breadcrumb: 'Technician' },
        loadChildren: () =>
          import('./modules/technician/technician.module').then(
            (m) => m.TechnicianModule
          ),
      },
      {
        path: 'partner',
        data: { breadcrumb: 'Partner' },
        loadChildren: () =>
          import('./modules/partner/partner.module').then(
            (m) => m.PartnerModule
          ),
      },
      {
        path: 'admin',
        data: { breadcrumb: 'Admin' },
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'sales',
        data: { breadcrumb: 'Sales' },
        loadChildren: () =>
          import('./modules/sales/sales.module').then((m) => m.SalesModule),
      },
      {
        path: 'partner',
        data: { breadcrumb: 'Partner' },
        loadChildren: () =>
          import('./modules/partner/partner.module').then(
            (m) => m.PartnerModule
          ),
      },
      {
        path: 'system',
        loadChildren: () =>
          import('./modules/system/system.module').then((m) => m.SystemModule),
      },
      {
        path: 'plant',
        loadChildren: () =>
          import('./modules/plant/plant.module').then((m) => m.PlantModule),
      },
      {
        path: 'ticket',
        loadChildren: () =>
          import('./modules/ticket/ticket.module').then((m) => m.TicketModule),
      },
      {
        path: 'package',
        loadChildren: () =>
          import('./modules/package/package.module').then(
            (m) => m.PackageModule
          ),
      },
      {
        path: 'invoice',
        data: { breadcrumb: 'Invoice' },
        loadChildren: () =>
          import('./modules/invoice/invoice.module').then(
            (m) => m.InvoiceModule
          ),
      },
      {
        path: 'quotation',
        data: { breadcrumb: 'การจัดการใบเสนอราคา' },
        loadChildren: () =>
          import('./modules/quotation/quotation.module').then(
            (m) => m.QuotationModule
          ),
      },
      {
        path: 'finance',
        data: { breadcrumb: 'การเงิน' },
        loadChildren: () =>
          import('./modules/finance/finance.module').then(
            (m) => m.FinanceModule
          ),
      },
      {
        path:'survey',
        data: { breadcrumb: 'สำรวจหน้างาน' },
        loadChildren: () =>
          import('./modules/survey/survey.module').then(
            (m) => m.SurveyModule
          ),
      },
      {
        path:'in-house',
        data: { breadcrumb: 'คำร้องติดตั้ง' },
        loadChildren: () =>
          import('./modules/in-house/in-house.module').then(
            (m) => m.InHouseModule
          ),
      }
    ],
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./modules/page/page.module').then((m) => m.PageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
