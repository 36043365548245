import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';  
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './modules/layout/app.layout.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ErrorInterceptor } from './guard/error.interceptor'; 

@NgModule({
  declarations: [
    AppComponent, 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule, 
    AppLayoutModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.domainApi!],
        disallowedRoutes: [environment.apiUrl + environment.masterApi],
      },
    }),
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    MessageService, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem('token-tech');
}