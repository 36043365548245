<div class="layout-topbar">
  <div class="topbar-start">
    <button
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger"
      (click)="onMenuButtonClick()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
  </div>

  <div class="topbar-end">
    <ul class="topbar-menu">
      <!-- <li class="topbar-search">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Search" class="w-12rem sm:w-full">
                </span>
            </li>
            <li class="ml-3">
                <button pButton type="button" icon="pi pi-cog" class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" (click)="onConfigButtonClick()"></button>
            </li> -->
 
      <div
      (click)="onProfileButtonClick()" 
      *ngIf="!hasSign"
      class="flex w-8 lg:align-items-start flex-column lg:flex-row p-2 bg-red-100 border-round border-1 border-red-300 mr-4 cursor-pointer"
    >
      <div class="flex align-items-start">
        <i class="pi pi-exclamation-triangle text-red-900 text-xl mr-3"></i>
        <div class="mr-3 flex lg:align-items-start flex-column lg:flex-row">
          <p class="m-0 p-0 text-red-700 line-height-3">
            ยังไม่ได้ทำการสร้าง signature ในระบบ
          </p>
        </div>
      </div>
    </div>
      <li class="flex flex-column text-right">
        <div class="font-semibold">{{ name }}</div>
        <div class="flex gap-3">
          <div class="">{{ email }}</div>
        </div>
      </li>
      <li class="topbar-profile">
        <button type="button" class="p-link" (click)="onProfileButtonClick()">
          <img
            *ngIf="avatarUrl"
            [src]="this.avatarUrl"
            alt="Profile"
            class="border-circle w-3rem h-3rem" 
          />
          <div *ngIf="!avatarUrl" class="flex w-3rem h-3rem bg-red-500 border-circle align-items-center justify-content-center">
            <i class="pi pi-user text-2xl text-white"></i>
          </div>
        </button>
      </li>
    </ul>
  </div>
</div>

<!-- 
<div
class="flex w-8 lg:align-items-start flex-column lg:flex-row p-4 bg-red-100 border-round border-1 border-red-300"
>
<div class="flex align-items-start">
  <i class="pi pi-exclamation-triangle text-red-900 text-2xl mr-3"></i>
  <div class="mr-3 flex lg:align-items-start flex-column lg:flex-row">
    <p class="m-0 p-0 text-red-700 line-height-3">
      ยังไม่ได้ทำการสร้าง signature ในระบบ
    </p>
  </div>
</div>
<a
  pRipple
  class="flex align-items-center ml-auto no-underline text-red-900 font-medium mt-3 lg:mt-0 cursor-pointer"
>
  <span>Update Profile</span>
  <i class="pi pi-arrow-right text-red-900 ml-2"></i>
</a>
</div> -->
