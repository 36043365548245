import { Component } from '@angular/core';
import { LayoutService } from '../../service/app.layout.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html',
})
export class AppProfileSidebarComponent {
  constructor(private router: Router, public layoutService: LayoutService) {}

  signOut() {
    this.navTo('/login');
    this.layoutService.state.profileSidebarVisible = false;
  }

  navTo(locat: string): void {
    this.router.navigate([locat]);
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }
}
