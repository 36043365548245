import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LayoutService } from '../../service/app.layout.service';
import { AuthService } from '../../service/auth.service';
import { MasterService } from '../../service/master.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor(
    public layoutService: LayoutService,
    private masterService: MasterService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    var user = this.authService.getUserProfile();

    var resp = await firstValueFrom(
      this.masterService.GetMenuPrivilegeList(user.userName!, user.role!)
    );

    this.model = [
      {
        label: 'เมนู',
        icon: 'pi pi-home',
        routerLink: ['/dashboard'],
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/dashboard'],
          },
        ],
      },
    ];

    resp.forEach((element) => {
      var item = {
        label: element.menuNameTh,
        icon: element.iconName,
        routerLink: [element.menuUrl],
        items: [{}],
      };
      // items: element.childMenus?.map((x) => {
      //   return {
      //     label: x.menuNameEn,
      //     routerLink: [x.menuUrl],
      //   };
      // }),
      if (element.childMenus?.length! > 0) {
        item.items = [];
        element.childMenus?.forEach((x) => {
          item.items.push({
            label: x.menuNameEn,
            routerLink: [x.menuUrl],
          });
        });
      }

      this.model[0].items.push({
        label: item.label,
        icon: item.icon,
        routerLink: item.routerLink,
        items: element.childMenus?.length! > 0 ? item.items : null,
      });
    });

    this.model[0].items.push({ label: 'ออกจากระบบ', routerLink: ['/login'] });

    // this.model = [
    //   {
    //     label: 'Dashboard',
    //     icon: 'pi pi-home',
    //     items: [
    //       {
    //         label: 'Main Dashboard',
    //         icon: 'pi pi-fw pi-home',
    //         routerLink: ['/dashboard'],
    //       },
    //     ]
    //   },
    //   {
    //     label: 'Menu',
    //     icon: 'pi pi-home',
    //     items: [
    //       {
    //         label: 'Customer',
    //         icon: 'pi pi-fw pi-comment',
    //         routerLink: ['/customer'],
    //       },
    //       {
    //         label: 'Interest',
    //         icon: 'pi pi-fw pi-heart',
    //         routerLink: ['/interest'],
    //       },
    //       {
    //         label: 'Ticket',
    //         icon: 'pi pi-fw pi-ticket',
    //         routerLink: ['/'],
    //       },
    //       {
    //         label: 'Technician',
    //         icon: 'pi pi-fw pi-wrench',
    //         routerLink: ['/'],
    //       },
    //       {
    //         label: 'Plants',
    //         icon: 'pi pi-fw pi-th-large',
    //         routerLink: ['/'],
    //       }
    //     ],
    //   },
    // ];
  }
}
