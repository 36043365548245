<div class="layout-container" [ngClass]="containerClass">
  <app-sidebar></app-sidebar>
  <div class="layout-content-wrapper">
    <app-topbar></app-topbar>
    <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu></app-profilemenu>

  <div class="layout-mask"></div>
</div>

<!-- 
<div
class="flex w-8 lg:align-items-start flex-column lg:flex-row p-4 bg-red-100 border-round border-1 border-red-300"
>
<div class="flex align-items-start">
  <i class="pi pi-exclamation-triangle text-red-900 text-2xl mr-3"></i>
  <div class="mr-3 flex lg:align-items-start flex-column lg:flex-row">
    <p class="m-0 p-0 text-red-700 line-height-3">
      ยังไม่ได้ทำการสร้าง signature ในระบบ
    </p>
  </div>
</div>
<a
  pRipple
  class="flex align-items-center ml-auto no-underline text-red-900 font-medium mt-3 lg:mt-0 cursor-pointer"
>
  <span>Update Profile</span>
  <i class="pi pi-arrow-right text-red-900 ml-2"></i>
</a>
</div> -->