import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../service/app.layout.service';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent implements OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    public router: Router
  ) {}

  ngOnInit() {
    this.getProfile();
    this.authService.checkHasSign().subscribe((len) => {
      this.getProfile();
    }); 
    
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.router.navigate([`../admin/admin-profile`]);
    //this.layoutService.showProfileSidebar();
  }
  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  email!: string;
  name!: string;
  hasSign = false;
  avatarUrl!: string;

  getProfile() {
    const userProfile = this.authService.getUserProfile();
    this.email = userProfile.email;
    this.name = userProfile.fullName;
    this.hasSign = userProfile.hasSignature;
    this.avatarUrl = userProfile.avatarUrl;
  }
}
